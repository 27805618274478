import React, { createContext, useContext, useEffect, useState } from 'react'
import { getPoints, rewardPoints } from 'services/points'
import { userTypeEnum } from 'services/user'
import { initUserMetaData } from 'services/track'
import { modalContext } from 'global/context/sub-contexts/modalContext'
import analytics from 'global/AnalyticsData'
import localStorageWrapper from 'util/LocalStorageWrapper'

const guestProfile = {
  name: 'Guest',
  isLoggedIn: false,
  points: 0,
  guest: true,
}

const isBrowser = () => typeof window !== 'undefined'

export const getProfile = () => {
  // we don't need context for profile - it's in local storage
  return isBrowser() && localStorageWrapper.getItem('dailybreakUser')
    ? JSON.parse(localStorageWrapper.getItem('dailybreakUser'))
    : guestProfile
}

export const getUserType = () => {
  const profile = getProfile()

  if (profile.isLoggedIn === true) {
    if (profile.guest) {
      return userTypeEnum.GUEST
    } else {
      return userTypeEnum.REGISTERED
    }
  } else {
    return userTypeEnum.NONE
  }
}

//Initialize context props so gatsby is happy when we try to destructure later
// TODO: userContext should be UserContext
const userContext = createContext({
  getProfile: function () {
    // we don't need context for profile - it's in local storage
    return isBrowser() && localStorageWrapper.getItem('dailybreakUser')
      ? JSON.parse(localStorageWrapper.getItem('dailybreakUser'))
      : guestProfile
  },
  setProfile: function () {},
  getSweepsEntries: function () {},
  setSweepsEntries: function () {},
  isLoggedIn: function () {
    return false
  },
  points: 0,
  setPoints: function (points=0) {},
  askToLogin: function () {},
  unauthorized: function () {},
  logout: function () {},
  isLoggedInBoolean: false,
  isGuest: true,
})

// when we first load up, try reading profile from local storage
// the user might have a cookie from a previous session
const initProfile = () => {
  return isBrowser() && localStorageWrapper.getItem('dailybreakUser')
    ? JSON.parse(localStorageWrapper.getItem('dailybreakUser'))
    : guestProfile
}

const UserProvider = ({ children }) => {
  const { Provider } = userContext

  const [internalProfile, setInternalProfile] = useState(guestProfile)
  const [points, setPoints] = useState(guestProfile.points)
  const { showSignUpModalTab } = useContext(modalContext)
  const [sweepsEntries, setSweepsEntries] = useState()

  // need to wait until browser/session storage is available to
  useEffect(() => {
    let tmp = initProfile()
    setInternalProfile(tmp)
    initUserMetaData(tmp)
    setPoints(tmp.points)
  }, [])

  // gets called after login by the login modal.
  const setProfile = profile => {
    // console.log(profile)
    if (isBrowser()) {
      profile.isLoggedIn = true
      profile.name = profile.firstName
      // also hang onto this in session storage
      localStorageWrapper.setItem('dailybreakUser', JSON.stringify(profile))

      setInternalProfile(profile)
      setPoints(parseInt(profile.points))
    }
  }

  const getSweepsEntries = () => {
    return sweepsEntries
  }

  const isLoggedIn = () => {
    return internalProfile.isLoggedIn
  }

  const handlePoints = points => {
    rewardPoints(points)
    setPoints(getPoints())
  }

  const askToLogin = () => {
    // showSignInModalTab()
    showSignUpModalTab()
  }

  // user tried doing something and a service declined
  const unauthorized = () => {
    if (internalProfile.isLoggedIn !== false) {
      logout()
    }
    // else no need to update
  }

  const logout = () => {
    setInternalProfile(guestProfile)
    analytics.setUser({})

    if (isBrowser()) {
      localStorageWrapper.removeItem('dailybreakUser')
      localStorageWrapper.removeItem('accesToken')
    }

    // todo: clean up any local storage / session storage from steps
  }

  return (
    <Provider
      value={{
        getProfile: getProfile,
        setProfile: setProfile,
        getSweepsEntries: getSweepsEntries,
        setSweepsEntries: setSweepsEntries,
        points: points,
        rewardPoints: handlePoints,
        setPoints: setPoints,
        isLoggedIn: isLoggedIn,
        askToLogin: askToLogin,
        unauthorized: unauthorized,
        logout: logout,
        isLoggedInBoolean: internalProfile.isLoggedIn,
        isGuest: internalProfile.guest,
      }}
    >
      {children}
    </Provider>
  )
}

export { userContext, UserProvider }
