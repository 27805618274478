import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

//Initialize context props so gatsby is happy when we try to destructure later
const modalContext = React.createContext({
  modalStatus: '',
  toggleModal: function () {},
  modalContent: {},
  setModalContent: function () {},
  showModalTemplate: function () {},
  showSignInModalTab: function () {},
  showSignUpModalTab: function () {},
  showForgotPasswordModal: function () {},
  onLoginSuccess: function () {},
  setOnLoginSuccess: function () {},
})

const ModalProvider = ({ children }) => {
  const [modalStatus, toggleModal] = useState('')
  const [modalContent, setModalContent] = useState()
  const [signInUpModal, setSignInUpModal] = useState(false)
  const [onLoginSuccess, setOnLoginSuccess] = useState()

  const { Provider } = modalContext

  const showModalTemplate = name => {
    setModalContent({ type: name })
    toggleModal('on')
  }

  //"showsigninmodal" has been replaced with a function articulating exactly what tab should be open at modal open time.
  const showSignInModalTab = (options = {}) => {
    setModalContent({ type: 'signinup', args: { tab: 1, options } })
    setSignInUpModal(true)
  }
  const showSignUpModalTab = (options = {}) => {
    setModalContent({ type: 'signinup', args: { tab: 0, options } })
    setSignInUpModal(true)
  }

  const showForgotPasswordModal = () => {
    setModalContent({ type: 'forgotpw' })
    toggleModal('on')
  }

  return (
    <Provider
      value={{
        modalStatus: modalStatus,
        toggleModal: toggleModal,
        modalContent: modalContent,
        setModalContent: setModalContent,
        // TODO: consider putting these in an object, {modal, set, show} to reduce clutter
        signInUpModal: signInUpModal,
        setSignInUpModal: setSignInUpModal,
        showForgotPasswordModal: showForgotPasswordModal,
        showModalTemplate: showModalTemplate,
        showSignInModalTab: showSignInModalTab,
        showSignUpModalTab: showSignUpModalTab,
        onLoginSuccess: onLoginSuccess,
        setOnLoginSuccess: setOnLoginSuccess,
      }}
    >
      {modalStatus === 'on' && modalContent ? (
        <Helmet
          bodyAttributes={{
            'data-modal-status': 'on',

            'data-modal-type': modalContent.type || '',
          }}
        />
      ) : (
        <Helmet
          bodyAttributes={{
            'data-modal-status': 'off',
            'data-modal-type': 'none',
          }}
        />
      )}
      {children}
    </Provider>
  )
}

export { modalContext, ModalProvider }
